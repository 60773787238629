.Btn {
  height: 48px;
  max-width: 290px;
  background-color: var(--color-primary);
  border-radius: 6px;
  font-size: 18px;
  font-weight: bold;
  color: var(--color-white);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.btnPlus {
  margin-right: 8px;
}

.label-btn-category {
  align-items: center;
  justify-content: center;
  padding: 0px 16px;
}

.align-btn-category {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0px;
}

.btn-organize {
  color: var(--color-primary);
  font-size: 18px;
  text-decoration: underline;
  cursor: pointer;
}

.dropdown-categories {
  width: 100%;
  max-width: 345px;
}

.search-items {
  width: 100% !important;
  margin-left: 16px !important;
}

.filter-category {
  width: 100%;
  margin: 8px 0px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;

  &.disabled-click {
    pointer-events: none;
    opacity: 0.7;
  }

  @media only screen and (max-width: 560px) {
    flex-direction: column;
    justify-content: space-around;
    width: 100%;

    .dropdown-categories {
      width: 95% !important;
      max-width: 95%;
      left: -8px;
      position: relative;
    }

    .search-items {
      width: 95% !important;
      margin-left: 0px !important;
    }
  }
}

.input-style {
  outline: none;
  height: 48px;
  border-radius: 8px;
  border: 1px solid var(--color-gray);
  font-size: 16px;
  padding: 0px 8px;
}

.input-style.large {
  width: 60%;
}

.input-style.short {
  width: 35%;
  position: relative;
  display: flex;
  align-items: center;
}

h3 {
  margin: 0;
  padding: 0;
  font-size: 32px;
  margin-bottom: 8px;
}

h4 {
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 24px !important;
}

h5 {
  margin: 0;
  color: var(--color-black);
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 24px;
}

.CardCategoria {
  border-radius: 6px;
  border: 1px solid var(--color-gray);
  padding: 16px 24px;
  color: var(--color-black);
  margin: 16px 0;
}

.CategoriaName {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 16px;
  border-bottom: 2px solid var(--color-light-gray);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Flex {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Bolder {
  font-weight: bolder;
}

.DivCardProduct {
  height: 100px;
  background-color: var(--color-white);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 24px;
}

.DivCardProduct:hover {
  background-color: var(--color-white);
}

.labelColumn {
  font-size: 16;
  font-weight: bold;
}

.CardProductImage {
  border: 1px dashed var(--color-gray);
  box-sizing: border-box;
  border-radius: 6px;
  height: 72px;
  width: 88px;
  margin-right: 16px;
  min-width: 88px;
  object-fit: cover;
}

.CardProductTitleItem {
  font-weight: bold;
  font-size: 16px;
}

.SideBarPainel {
  width: 60%;
}

.JustifyBetween {
  justify-content: space-between;
}

.SectionCupom {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.GerateCode {
  margin-left: 8px;
  font-weight: bold;
  color: var(--color-orange);
  cursor: pointer;
}

.GerateCode:hover {
  color: var(--color-orange-secondary);
}

.SectorOptions {
  width: 45%;
  padding: 16px;
}

.OptionLine {
  border: 1px solid var(--color-white);
  padding: 12px;
  cursor: pointer;
}

.OptionLine:hover {
  background-color: var(--color-white);
}

.align-items-product {
  display: flex;
  align-items: center;
}

.align-description {
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 768px) {
    min-width: 150px;
  }
}

.text-name-product {
  font-size: 16px;
  font-weight: bold;
}

.description-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-break: break-all;
  -webkit-line-clamp: 4;
  max-height: 150px;
  width: 100%;
  max-width: 100%;
}

.align-content {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.border-price {
  display: flex;
  justify-content: center;
  overflow: hidden;
  width: 100px;
  height: 35px;
  border: 1px solid var(--color-gray);
  border-radius: 8px;
}

.align-icon-save {
  position: absolute;
  cursor: pointer;
  margin-left: 130px;
  top: 14px;
}

.invalid-price {
  border: 1px solid var(--color-primary) !important;
}

.hidden {
  display: none;
  visibility: hidden;
}

.invalid-price-text {
  display: inline-block;
  font-size: 12px;
  color: var(--color-primary);
  font-weight: 100;
  position: absolute;
  top: 45px;
}

.input-price {
  width: 100%;
  border: none;
  outline: none;
  text-align: center;
  color: var(--color-dark-gray);
}

.switch-btn {
  position: relative;
  width: 110px;
  height: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--color-gray);
  font-size: 14px;
  cursor: pointer;
  background-color: #f2f2f2;
  box-shadow: 0px 0px 2px var(--color-shadow-black);
  border-radius: 8px;
  overflow: hidden;
  font-weight: 100;
}

.active {
  z-index: 2;
  color: var(--color-white);
}

.disable {
  color: var(--color-gray);
}

.selected {
  position: absolute;
  height: 24px;
  width: 55px;
  z-index: 1;
  font-weight: bold;
  background-color: var(--color-primary);
  border-radius: 8px;
  border-radius: 8px;
  transition: 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15) all;
}

.right {
  right: 0 !important;
}

.left {
  left: 0 !important;
}

.add-items {
  width: 180px;
  border: 1px solid var(--color-primary);
  font-size: 14px !important;
  height: 40px !important;
  margin: 8px 0px;
}

.add-items:hover {
  background-color: var(--color-primary);
  color: var(--color-white);
}

.save-updates {
  background-color: var(--color-primary);
  color: var(--color-white);
}

.save-updates:hover {
  background-color: var(--color-red-rose);
}

.align-icon {
  display: flex;
  align-items: center;
  padding-right: 8px;
  cursor: pointer;
}

.empty-category {
  font-size: 18px;
  font-family: 'MontserrattBold';
  ;
  ;
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 16px 0px;
}