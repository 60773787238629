@import '../../../../assets/styles/colors.scss';

.plans-container {
  display: flex;
  gap: 16px;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: flex-start;
  padding-bottom: 16px;

  &__card {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.5);

    max-width: 330px;

    &-content {
      justify-content: center;
      align-items: center;
      display: flex;
      flex-direction: column;
      padding: 16px;
    }

    &-title {
      font-size: 24px;
      color: white;
      padding: 0px;
      margin: 0px;
      text-align: left;
      width: 100%;
      font-family: 'Montserrat';
    }

    &__price {
      display: inline-flex;
      align-items: baseline;
      font-family: 'MontserrattBold';
      ;
      gap: 4px;
      color: white;
      padding: 24px 0px;

      &-value {
        margin: 0;
        font-size: 48px;
      }

      &-period {
        margin: 0;
        font-size: 12px;
      }
    }

    &-buy-plan-btn {
      width: 80%;
      padding: 16px;
      font-size: 18px;
      border-radius: 4px;
      font-family: 'MontserrattBold';
      ;
      background-color: var(--color-white);
      color: var(--color-red-rose);
      border: none;
      outline: none;
      cursor: pointer;
      box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
    }

    &__benefits {
      width: 80%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 24px 0px;
      padding-bottom: 0px;
      font-family: 'Montserrat';
      color: var(--color-white);

      &-item {
        font-size: 12px;
        font-family: 'MontserrattBold';
        ;
        gap: 4px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
      }
    }

    &-footer-btn {
      width: 100%;
      background-color: var(--color-primary-hover);
      font-size: 12px;
      font-family: 'MontserrattBold';
      ;
      color: var(--color-white);
      border: none;
      outline: none;
      cursor: pointer;
      padding: 16px 0px;
      border-radius: 0px 0px 4px 4px;

      &:hover {
        opacity: 0.75;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
}

.annual {
  background-color: var(--color-red-rose);
}

.monthly {
  background-color: var(--color-white);

  &-dark-color {
    color: var(--color-black) !important;
  }

  &-primary-color {
    background-color: var(--color-red-rose) !important;
    color: var(--color-white) !important;
  }

  &-footer-btn {
    background-color: var(--color-gray)-white !important;
    color: var(--color-black) !important;
  }
}

.active-plan {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 16px 0px;
  width: 100%;

  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: center;
    align-items: center;
    max-width: 500px;

    &__infos {
      background-color: var(--color-gray)-white;
      width: 130px;
      height: 100px;
      border-radius: 8px;
      padding: 8px;
      display: flex;
      flex-direction: column;
      margin: 0px;
      justify-content: space-between;
      align-items: center;

      &-name {
        font-family: 'MontserrattBold';
        ;
        ;
        font-size: 16px;
      }

      &-days {
        font-family: 'MontserrattBold';
        ;
        ;
        font-size: 42px;
      }

      &-label {

        font-size: 14px;
      }
    }
  }
}