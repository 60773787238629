@import '../../../assets/styles/colors.scss';

.card-user-order {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 450px;
  padding: 8px;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: var(--color-white);
  margin-bottom: 16px;
  -webkit-box-shadow: 0px 0px 4px 1px var(--color-shadow-black);
  -moz-box-shadow: 0px 0px 4px 1px var(--color-shadow-black);
  box-shadow: 0px 0px 4px 1px var(--color-shadow-black);
  max-height: 109px;
  min-height: 109px;
  cursor: pointer;
  border: 1px solid var(--color-light-gray);

  &__logo-store {
    display: inline-flex;
    width: 90px;
    height: 90px;
    overflow: hidden;
    border-radius: 8px;
    margin-right: 16px;

    &-image {
      object-fit: contain;
      width: 100%;
    }
  }

  &__order-details {
    width: calc(100% - 106px);
    height: 109px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;

    &__inline {
      width: 100%;
      height: 100%;
      display: inline-flex;
      align-items: center;
      justify-content: space-between;

      &-id-order {
        font-family: 'MontserrattBold';
        ;
        ;
        color: var(--color-primary);
        font-size: 14px;
      }

      &-arrow-icon {
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
      }

      &-price {
        small {
          color: var(--color-dark-gray);
          font-family: 'MontserratLight';
          font-size: 10px;
        }

        color: var(--color-primary-green);
        font-family: 'MontserrattBold';
        ;
        ;
        font-size: 14px;
      }

      &-date {
        color: var(--color-dark-gray);
        font-size: 12px;
        font-family: 'MontserratLight';
      }
    }

    &__column {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      &-label-status {
        font-size: 14px;
      }

      &-status {
        color: var(--color-primary);
        font-size: 14px;
      }
    }

    .dark-grey {
      color: var(--color-dark-gray) !important;
    }
  }
}