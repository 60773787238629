@import '../../../../assets/styles/colors.scss';

.plan-billing-infos {
  width: 100%;
  border-radius: 8px 8px 0px 0px;

  &-tabs {
    background-color: var(--color-light-gray);
    width: 100%;
    max-width: 500px;
    display: inline-flex;
    justify-content: space-between;

    font-size: 16px;
    border-radius: 8px 8px 0px 0px;
    overflow: hidden;
    cursor: pointer;

    span {
      padding: 10px 0px;
      width: 100%;
      text-align: center;

      &:not(:first-child) {
        border-left: 1px solid var(--color-white);
      }
    }

    &-active {
      background-color: var(--color-red-rose);
      color: var(--color-white);
      font-family: 'MontserrattBold';
      ;
      ;
    }
  }

  &__signature {
    padding: 16px 0px;
    display: flex;
    flex-direction: column;
    gap: 4px;

    font-size: 14px;
    max-width: 500px;

    &__content {
      display: inline-flex;
      gap: 8px;

      :not(:first-child) {
        font-family: 'MontserrattBold';
        ;
        ;
      }
    }

    &-cancel-plan {
      color: var(--color-dark-gray);
      background-color: transparent;
      border: none;
      outline: none;
      font-family: 'MontserratLight';
      cursor: pointer;
      font-size: 14px;
      width: 180px;
      text-align: left;
      padding: 0px;
    }

    &-recurring-payment-canceled {
      display: block;
      margin-bottom: 16px;
      font-size: 16px;
      font-family: 'MontserrattBold';
      ;
      ;
    }
  }

  &__cards {
    padding-top: 16px;
    max-width: 500px;

    .plan-billing-infos-tabs {
      border-radius: 0px;

      span {
        padding: 8px;
        font-size: 12px;
        font-family: 'MontserrattBold';
        ;
        ;
        text-align: left;
        padding-left: 8px;
        cursor: default;
      }
    }

    &__content {
      width: 100%;
      display: inline-flex;
      justify-content: space-between;

      span {
        width: 173px;
        text-align: left;
        border-bottom: 1px solid var(--color-black);
        padding: 4px;

        font-size: 14px;

        &:not(:first-child) {
          border-left: 1px solid var(--color-black);
        }
      }

      &-pattern {
        display: inline-flex;
        gap: 8px;
        text-transform: lowercase;
        align-items: center;

        &::first-letter {
          text-transform: capitalize;
        }

        &__remove {
          display: inline-flex;
          width: 160px;
          justify-content: space-between;

          &-btn {
            background: transparent;
            border: none;
            cursor: pointer;
          }
        }
      }
    }
  }

  &__history {
    padding-top: 16px;
    overflow: auto;

    &-copy-pix-btn {
      border: none;
      outline: none;
      background: transparent;
      color: var(--color-red-rose);
      font-family: 'MontserrattBold';
      ;
      ;
    }
  }
}