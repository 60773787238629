.header {
  width: 100%;
  height: 80px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;

  &.fixed-header {
    position: fixed;
    background-color: var(--color-white);
    z-index: 9999;
  }

  &-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 80%;
    max-width: 1440px;
  }

  &__left-section {
    display: flex;
    align-items: center;
    justify-content: center;

    &-logo {
      width: 150px;
      z-index: 5;
    }
  }

  &__right-section {
    display: flex;
    align-items: center;
    justify-content: center;

    &__header-desktop {
      &-option {
        display: inline-block;
        padding: 0px 16px;

        color: var(--color-black);
        cursor: pointer;
        text-decoration: none;

        &:hover {
          font-family: 'MontserrattBold';
          ;
          ;
          color: var(--color-red-rose);
        }

        &.current-section {
          font-family: 'MontserrattBold';
          ;
          ;
          color: var(--color-red-rose);
        }
      }

      &-highlight {
        background: var(--color-red-rose);
        border-radius: 8px;
        padding: 8px;
        color: var(--color-white);
        font-family: 'MontserrattBold';
        ;

        &:hover {
          color: var(--color-black);
        }
      }
    }

    &__header-mobile {
      height: 0px;
      position: relative;

      &-close {
        -webkit-transition: left 0.5s ease-in-out;
        -moz-transition: left 0.5s ease-in-out;
        -o-transition: left 0.5s ease-in-out;
        transition: left 0.5s ease-in-out;
        left: -250px !important;
        z-index: 10;
      }

      &-open {
        -webkit-transition: left 0.5s ease-in-out;
        -moz-transition: left 0.5s ease-in-out;
        -o-transition: left 0.5s ease-in-out;
        transition: left 0.5s ease-in-out;
        left: 0px;
        z-index: 10;
      }

      &-hamburguer-icon {
        z-index: 6;
        position: absolute;
        right: 16px;
        bottom: -10px;
      }

      &__sidebar {
        margin-top: 100px;
        padding: 8px;

        &-nav {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }

  @media only screen and (max-width: 1024px) {
    &-container {
      width: 90%;
    }
  }

  @media only screen and (max-width: 790px) {
    &__right-section {
      &__header-desktop {
        &-option {
          padding: 0px 8px;
        }
      }
    }
  }
}

.mobile-sidebar-close {
  -webkit-transition: width 0.5s ease-in-out;
  -moz-transition: width 0.5s ease-in-out;
  -o-transition: width 0.5s ease-in-out;
  transition: width 0.5s ease-in-out;
  width: 0px;
}

.mobile-sidebar-open {
  -webkit-transition: width 0.5s ease-in-out;
  -moz-transition: width 0.5s ease-in-out;
  -o-transition: width 0.5s ease-in-out;
  transition: width 0.5s ease-in-out;
  width: 100vw;
}