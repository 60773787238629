@font-face {
  font-family: MontserrattBold;
  src: url('../fonts/Montserrat-Bold.ttf');
}

@font-face {
  font-family: MontserrattNormal;
  src: url('../fonts/Montserrat-Regular.ttf');
}

@font-face {
  font-family: MontserratLight;
  src: url('../fonts/Montserrat-Light.ttf');
}

@font-face {
  font-family: KaushanScriptRegular;
  src: url('../fonts/KaushanScript-Regular.ttf');
}

//fonts
$KaushanScript: KaushanScriptRegular;