.card-product {
  width: 100%;
  height: 150px;
  display: flex;
  box-sizing: border-box;
  padding: 8px;
  border-radius: 16px;
  background-color: #ffffff;
  -webkit-box-shadow: 0px 0px 4px 1px var(--color-mid-gray);
  -moz-box-shadow: 0px 0px 4px 1px var(--color-mid-gray);
  box-shadow: 0px 0px 4px 1px var(--color-mid-gray);
  margin-bottom: 16px;
  cursor: pointer;

  .image-content {
    width: 150px;
    min-width: 150px;
    height: 100px;
    object-fit: contain;

    .image-product {
      width: 100%;
      height: 100%;
      border-radius: 16px;
      object-fit: contain;
    }
  }

  .details-product {
    display: flex;
    flex-direction: column;
    margin-left: 16px;
    justify-content: space-between;

    .name {
      font-family: 'MontserrattBold';
      ;
      ;
      font-size: 16px;
      margin-bottom: 2px;
    }

    .description {
      font-family: 'MontserratLight';
      font-size: 14px;
      height: 65px;
      max-height: 100px;

      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
    }

    .price {
      small {
        color: #999999;
        font-size: 10px;

      }

      font-family: 'MontserrattBold';
      ;
      ;
      color: var(--color-primary-green);
      display: flex;
      align-items: center;
      font-size: 14px;
    }
  }
}