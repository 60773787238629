.documentations {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  overflow-y: auto;

  &__container {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 585px;

    &__header {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0px 8px;
      margin-bottom: 16px;

      &__title {
        font-family: 'MontserrattBold';
        ;
        ;
        font-size: 32px;
      }

      &__input {
        width: 100%;
        max-width: 585px;
        height: 46px;
        border-radius: 16px;
        border: 1px solid var(--color-red-rose);
        outline: none;
        padding-left: 8px;
      }
    }

    &__documentations {


      &__section {
        display: block;
        margin-bottom: 8px;

        &__type {
          display: inline-block;
          color: var(--color-red-rose);
          font-family: 'MontserrattBold';
          ;
          ;
          text-decoration: none;
          margin-bottom: 8px;

          &-light-color {
            color: var(--color-white);
          }

          &:hover {
            color: var(--color-dark-gray);
          }

          &__content {
            display: inline-block;
            display: flex;
            flex-direction: column;
            color: var(--color-dark-gray);
            text-decoration: none;
            padding: 0px 0px 8px 16px;
            list-style: circle;

            &:hover {
              color: var(--color-red-rose);
            }
          }
        }
      }
    }
  }
}