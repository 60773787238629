.dl-card {
  position: relative;
  padding: 0px 32px;
  max-width: 300px;
  min-width: 150px;
  height: 150px;
  background: var(--color-white);
  box-shadow: 0px 4px 12px var(--color-shadow-black);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;


  &__custom-button {
    position: absolute;
    top: 8px;
    right: 8px;
    display: flex;
    align-items: center;
    cursor: pointer;

    &__button {

      border: none;
      background: transparent;
      font-size: 14px;
      cursor: pointer;
    }
  }

  &-data {
    font-size: 40px;
    font-family: 'MontserrattBold';
    color: var(--color-red-rose);
    margin: 0;
  }

  &-title {
    font-size: 14px;
    font-family: 'MontserrattBold';
    color: var(--color-black);
    margin: 0;
    position: absolute;
    bottom: 8px;
    text-align: center;
  }

  &-skeleton {
    display: flex;
    width: 300px;
  }
}