.how-it-works {
  width: 100%;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  background-color: var(--color-gray)-white;
  padding: 16px;

  &-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    width: 80%;
    max-width: 1440px;
  }

  &__informative_texts {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 32px;

    &-title {
      font-family: 'MontserrattBold';
      ;
      ;
      color: var(--color-dark);
      font-size: 48px;
      margin-bottom: 16px;
      color: var(--color-red-rose);
    }

    &-subtitle {
      font-size: 18px;
      color: var(--color-dark);
    }
  }

  &-align-steps {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__step {
      display: flex;
      align-items: center;
      margin-bottom: 32px;

      &-number {
        height: 40px;
        width: 40px;
        min-width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 'MontserrattBold';
        ;
        ;
        background-color: var(--color-purple);
        border-radius: 100%;
        color: var(--color-white);
        margin-right: 8px;
      }

      &-label {
        margin: 0 !important;
        font-family: 'MontserrattBold';
        ;
        ;
        color: var(--color-dark);
        max-width: 200px;
      }
    }

    &-arrow {
      transform: rotate(270deg);
    }
  }

  &__button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 48px;

    &-btn {
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    }
  }

  @media only screen and (max-width: 1024px) {
    &-container {
      width: 90%;
    }
  }

  @media only screen and (max-width: 768px) {
    &-container {
      width: 95%;
      align-items: center;
    }

    &__informative_texts {
      &-title {
        width: 100%;
        font-size: 40px;
      }

      &-subtitle {
        margin-bottom: 8px;
      }
    }

    &-align-steps {
      flex-direction: column;
    }

    &__content {
      &__step {
        &-label {
          font-size: 24px;
          max-width: 100%;
        }
      }

      &-arrow {
        transform: rotate(0deg);
      }
    }
  }
}