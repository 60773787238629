.form-contact {
  width: 100%;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  background-color: var(--color-gray)-white;
  overflow: hidden;
  padding: 16px;

  &-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    width: 80%;
    max-width: 1440px;
  }

  &__sections {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;

    &-image {
      position: absolute;
      bottom: 0;
      left: 0;
      height: 100%;
    }

    &__contact {
      display: flex;
      flex-direction: column;
      max-width: 670px;
      z-index: 2;

      &-title {
        font-size: 48px;
        color: var(--color-dark);
        font-family: 'MontserrattBold';
        ;
        ;
        margin-bottom: 16px;
      }

      &-description {
        margin: 0;
        color: var(--color-dark);
      }

      &-button {
        max-width: 250px;
      }
    }
  }

  @media only screen and (max-width: 1024px) {
    &-container {
      width: 90%;
    }
  }

  @media only screen and (max-width: 768px) {
    &-container {
      width: 95%;
      align-items: center;
    }

    &__sections {
      justify-self: center;

      &__contact {
        width: 100%;

        &-title {
          font-size: 42px;
        }

        &-button {
          width: 100% !important;
          max-width: 100% !important;
        }
      }
    }

    .input-content {
      min-width: 100% !important;
      width: 100% !important;
    }
  }
}