.order-information {
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
  }

  .letter-spacing {
    letter-spacing: 0.08em;
  }

  .pointer {
    position: absolute;
    width: fit-content;
    font-size: 14px;
    letter-spacing: 0.08em;
    z-index: 0;
    top: 0px;
  }

  @media only screen and (min-width: 768px) {
    padding-bottom: 0px;
    background-color: var(--color-light-gray);
    overflow-y: auto;

    .order-information-content {
      max-width: 768px;
    }
  }
}

.order-tooltip {
  .order-tooltip-content {
    display: none;
  }

  &:hover {
    .order-tooltip-content {
      position: absolute;
      width: auto;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 4px;
      border: 1px solid var(--color-gray);
      background-color: var(--color-white);
      z-index: 5;
    }
  }
}