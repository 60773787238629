.send-ticket {
  width: 100%;
  display: flex;
  flex-direction: column;

  &__content {
    width: 100%;
    display: flex;
  }

  &-form {
    width: 100%;
    max-width: 450px;
    padding: 8px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    box-shadow: 0px 4px 4px #00000040;
  }

  &__options {
    display: flex;
    flex-direction: column;
    margin-left: 16px;
    margin-top: 8px;

    &__feedback {
      display: inline-flex;
      align-items: center;
      font-family: 'MontserrattBold';
      ;
      ;
      margin-bottom: 16px;

      &-label {
        margin-left: 4px;
      }
    }

    &-label {
      margin-bottom: 8px;
      font-family: 'MontserratLight';
    }

    &__group-bts {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      &-btn {
        padding: 0;
        cursor: pointer;
        display: inline-flex;
        align-items: center;
        font-family: 'MontserratLight';
        outline: none;
        border: none;
        background-color: transparent;
        margin-bottom: 8px;

        &-label {
          margin-left: 4px;
        }

        &:hover {
          color: var(--color-red-rose);
        }
      }
    }

    &__details {
      display: flex;
      flex-direction: column;
      cursor: pointer;
      font-size: 12px;

      &__icon-container {
        display: inline-flex;
        align-items: center;
      }

      &-label {
        font-family: 'MontserratLight';
        margin: 0px 4px;

        &:hover {
          color: var(--color-red-rose);
        }
      }

      &-link {
        color: var(--color-black);
      }

      &-datas {
        display: flex;
        flex-direction: column;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__content {
      flex-direction: column;
      align-items: center;
    }

    &-form {
      margin-bottom: 16px;
    }
  }
}

.ticket-loading {
  position: absolute;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 999;

  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: center;
  background-color: #171717f1;

  &__top-section {
    display: flex;
    align-items: center;

    &-label {
      font-family: 'MontserrattBold';
      ;
      ;
      color: var(--color-red-rose);
      margin: 0px !important;
      margin-right: 8px;
    }

    &-icon {
      margin-left: 16px;
    }
  }

  &-sub-label {
    font-family: 'MontserratLight';
    color: var(--color-white);
  }
}