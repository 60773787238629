.time-picker {
  position: relative;
  border-radius: 4px;
  padding: 0px 4px;

  &__list-container {
    left: 0px;
    max-height: 50vh;
    width: 80px;
    overflow-y: auto;
    position: absolute;
    box-shadow: var(--bs-box-shadow-sm);
    z-index: 1;

    &-option {
      cursor: pointer;
      text-align: center;
      padding: 8px;
      font-weight: normal;

      &:hover {
        background-color: var(--color-red-rose);
        color: var(--color-white);
        font-weight: bold;
      }
    }
  }

  &:hover {
    cursor: pointer;
    background-color: var(--color-red-rose);
    color: var(--color-white);
    font-weight: bold;
  }
}