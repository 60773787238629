.resumo-container {
  padding: 16px;

  .label-filter {
    font-weight: bold;
    color: var(--color-red-rose);
  }

  .period-filter {
    display: flex;
    justify-content: space-between;
    width: fit-content;
    align-items: flex-end;
    gap: 8px;
    font-weight: bold;
    flex-wrap: wrap;
  }

  .list-cards {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    padding-top: 8px;

    .card {
      border-radius: 8px;
      padding: 16px;

      @media only screen and (min-width: 768px) {
        width: 310px;
      }

      @media only screen and (max-width: 768px) {
        width: 100%;
      }

      .card-title {
        color: var(--color-red-rose);
        font-weight: bold;
      }

      .card-value {
        font-weight: bold;
        font-size: 32px;
      }
    }
  }

  .section-grid {
    @media only screen and (min-width: 768px) {
      width: calc(100%);
    }

    @media only screen and (max-width: 768px) {
      width: calc(100vw - 36px);
    }

    overflow-x: auto;
  }

  .line-nav {
    display: flex;
    flex-direction: row;
    margin: 16px 0;
    width: 100%;

    .card-config {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border-bottom: 1px solid var(--color-gray);
      padding: 0px 8px;
      color: var(--color-dark-gray);
      padding-bottom: 8px;
      z-index: 2;
      text-decoration: none;

      &:hover {
        padding-bottom: 7px;
        border-bottom: 2px solid var(--color-primary);
        color: var(--color-primary);
      }
    }

    .card-active {
      border-bottom: 2px solid var(--color-primary);
      color: var(--color-primary);
      font-weight: bold;

      &:hover {
        padding-bottom: 8px !important;
      }
    }
  }

  .section-charts {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 16px;
    padding-top: 16px;
    color: var(--color-red-rose);
    font-weight: bold;
    border-top: 1px solid var(--color-gray);
  }
}

.section-fields {
  border-bottom: 2px solid var(--color-primary);
  border-radius: 8px;
  padding: 4px;
  margin-bottom: 16px;

  h4 {
    color: var(--color-red-rose);
    margin-bottom: 8px !important;
  }

  .form-label {
    font-weight: bold;
  }
}

@media only screen and (max-width: 768px) {
  .content-modal {
    width: calc(100vw - 60px);
  }
}

@media only screen and (min-width: 768px) {
  .content-modal {
    width: 720px;
  }
}