.max-w-fit {
    max-width: fit-content;
}

.cursor-pointer {
    cursor: pointer
}

.fw-bold {
    font-family: 'MontserrattBold';
}

.dl-textarea {
    width: 100%;
    max-width: 100%;
    box-sizing: 'border-box';
    height: 123px;
    min-height: 123px;
    max-height: 123px;
    resize: none;
    border-radius: 8px;
    border: 1px solid var(--color-gray);
    outline: none;
    font-family: 500;
    font-size: 14px;
}

* {
    --bs-body-font-family: Montserrat, monospace;
    --bs-nav-link-color: #1d1d1b;
    --bs-primary-text-emphasis: #1d1d1b;
    --bs-nav-tabs-link-active-color: var(--color-primary);
    --bs-primary-rgb: var(--color-primary);
    --bs-nav-tabs-link-hover-border-color: var(--color-primary);
    --bs-nav-link-hover-color: var(--color-primary);
    --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem hsla(344, 96%, 48%, 0.3);
    --bs-primary-bg-subtle: hsla(344, 96%, 48%, 0.1);
}

html {
    --color-white: #ffffff;

    --color-black: #1d1d1b;
    --color-shadow-black: #00000026;

    --color-primary: #e10036;
    --color-primary-light: #e10036;
    --color-primary-hover: #da2e4c;
    --color-red-rose: #f10542;
    --color-primary-shadow: #e4001b27;

    --color-gray: #bdbdbd;
    --color-light-gray: #f8f8f8;
    --color-mid-gray: #dcdcdc;
    --color-dark-gray: #828282;

    --color-light-yellow: #ffe600;
    --color-yellow: #ffe600;

    --color-orange: #fd983a;
    --color-orange-secondary: #cd5600;

    --color-green: #6df63d;
    --color-green-hover: #33c600;
    --color-primary-green: #27ae60;

    --color-blue: #2f80ed;
    --color-purple: #5600e1;
    --color-opacity: "rgba(0; 0; 0; 0.5)";
    --color-transparent: "rgba(52; 52; 52; 0.8)";

    --store-color: var(--color-primary);

    .bg-primary {
        background-color: var(--color-primary) !important;
    }

    .text-primary {
        color: var(--color-primary) !important;
    }

    .text-red-rose {
        color: var(--color-red-rose) !important;
    }

    .text-purple {
        color: var(--color-purple) !important
    }

    .bg-primary-hover {
        background-color: var(--color-primary-hover) !important;
    }

    .border-primary {
        border-color: var(--color-primary-hover) !important;
    }

    .btn-primary {
        --bs-btn-border-color: var(--color-primary);
        --bs-btn-disabled-bg: var(--color-primary);
        --bs-btn-disabled-border-color: var(--color-primary);
        --bs-btn-bg: var(--color-primary);
        --bs-btn-hover-border-color: var(--color-primary-hover);
        --bs-btn-hover-bg: var(--color-primary-hover);
        --bs-btn-active-bg: var(--color-primary-hover);
        --bs-btn-active-border-color: var(--color-primary-hover);
    }

    .btn-outline-primary {
        --bs-btn-color: var(--color-primary);
        --bs-btn-border-color: var(--color-primary);
        --bs-btn-hover-color: #fff;
        --bs-btn-hover-bg: var(--color-primary);
        --bs-btn-hover-border-color: var(--color-primary);
        --bs-btn-focus-shadow-rgb: 13, 110, 253;
        --bs-btn-active-color: #fff;
        --bs-btn-active-bg: var(--color-primary);
        --bs-btn-active-border-color: var(--color-primary);
        --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
        --bs-btn-disabled-color: var(--color-primary);
        --bs-btn-disabled-bg: transparent;
        --bs-btn-disabled-border-color: var(--color-primary);
        --bs-gradient: none;
    }

    .btn-dark {
        --bs-btn-bg: var(--color-black);
    }

    .icon-size-lg {
        font-size: 75px;
    }

    body {
        margin: 0;
        font-family: 'Montserrat', sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    code {
        font-family: 'Montserrat', sans-serif;
    }

    /* width */
    ::-webkit-scrollbar {
        width: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: var(--color-white);
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: var(--color-gray);
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: var(--color-dark-gray);
    }

    .fs-7 {
        font-size: 14px;
    }

    .fs-8 {
        font-size: 12px;
    }

    .fs-9 {
        font-size: 10px;
    }
}