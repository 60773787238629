@import '../../../assets/styles/colors.scss';

.dl-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
  max-height: 200px;

  &-title-modal {
    font-size: 24px;
    font-family: 'MontserrattBold';
    color: var(--color-black);
    margin: 8px 0px;
  }

  &-txt-warning {
    margin: 8px 0px;

    color: var(--color-black);
  }

  &-align-btn {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row;

    .btn-modal,
    .btn-modal-cancel {
      outline: none;
      width: 45%;

      &:hover {
        background-color: none !important;
      }
    }

    .btn-modal-cancel {
      background-color: var(--color-white);
      border-color: var(--color-primary);
      color: var(--color-primary);
    }
  }
}