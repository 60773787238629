.billing-data {
  width: 100%;
  display: flex;
  flex-direction: column;

  &__card-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;

    &-card {
      margin: 8px;
    }

    @media only screen and (max-width: 768px) {
      justify-content: center;
    }
  }
}

.modal-withdraw {
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: flex-start;
  padding: 16px 24px;

  &__title {
    text-align: center;
    font-size: 32px;
    font-family: 'MontserrattBold';
    margin: 0;
    color: var(--color-red-rose);
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 8px 0px;

    p {
      margin: 0;
    }
  }

  &__request {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 16px;

    &__message-error {
      font-size: 12px;
      color: var(--color-red-rose);
    }
  }

  &__buttons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;
  }
}