@import '../assets/styles/colors.scss';

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: var(--color-primary-green);
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark {
  z-index: 2;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: var(--color-white);
  stroke-miterlimit: 10;
  margin: 10% auto;
  box-shadow: inset 0px 0px 0px var(--color-primary-green);
  background: var(--color-primary-green);
  animation:
    fill 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.9s both;
  animation: bgColor 1s ease-in-out;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {

  0%,
  100% {
    transform: none;
  }

  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px var(--color-red-rose);
  }
}

@keyframes bgColor {
  0% {
    background-color: transparent;
  }

  100% {
    background-color: var(--color-red-rose);
  }
}

.animated-svg {
  .flex {
    display: flex;
  }

  .icon {
    position: relative;
    justify-content: center;
    width: 5em;
    height: 5em;
    margin: 1.25em auto 1.875em;
    border: 0.25em solid transparent;
    border-radius: 50%;
    line-height: 5em;
    cursor: default;
    box-sizing: content-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    zoom: normal;
  }

  .icon-text {
    font-size: 3.75em;
  }

  .icon.error {
    border-color: var(--color-red-rose);
  }

  .icon.error .mark {
    position: relative;
    flex-grow: 1;
  }

  .icon.error [class^='mark-line'] {
    display: block;
    position: absolute;
    top: 2.3125em;
    width: 2.9375em;
    height: 0.3125em;
    border-radius: 0.125em;
    background-color: var(--color-red-rose);
  }

  .icon.error [class^='mark-line'][class$='left'] {
    left: 1.0625em;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .icon.error [class^='mark-line'][class$='right'] {
    right: 1em;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
}