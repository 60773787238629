@import '../../../assets/styles/colors.scss';

.without-plan-container {
  width: 100%;
  max-width: 550px;
  gap: 16px;
  display: grid;
}

.box-total {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.box-day-subscribe {
  background-color: var(--color-gray)-white;
  padding: 8px;
  border-radius: 8px;
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 32px 0px;

  &-plan {
    font-weight: bold;
    font-size: 14px;
  }

  &-day {
    font-weight: bold;
    font-size: 32px;
  }

  &-restant {
    font-size: 14px;
  }
}