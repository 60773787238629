.about-us {
  width: 100%;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  background-color: var(--color-gray)-white;
  overflow: hidden;
  padding: 16px;

  &-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    width: 80%;
    max-width: 1440px;
  }

  &-title {
    width: 100%;
    display: flex;
    align-items: flex-start;
    font-size: 48px;
    color: var(--color-dark);
    font-family: 'MontserrattBold';
    margin: 0;
    margin-bottom: 24px;
  }

  &__sections {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__informative-text {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      max-width: 800px;

      &-text {
        color: var(--color-dark);
        margin-bottom: 16px;
      }
    }

    &-logo {
      height: 100px;
      max-width: 240px;
      padding-left: 16px;
    }
  }

  @media only screen and (max-width: 1024px) {
    &-container {
      width: 90%;
    }
  }

  @media only screen and (max-width: 768px) {
    &-container {
      width: 95%;
      align-items: center;
    }

    &-title {
      font-size: 40px;
    }

    &__sections {
      flex-direction: column;
      align-items: center;

      &-logo {
        margin-top: 24px;
        padding-left: 0px;
      }
    }
  }
}