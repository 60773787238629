.user-orders {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  &-container {
    max-width: 470px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding: 8px;
    // background-color: var(--color-mid-gray);
    border-radius: 8px;
  }

  &__header {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    max-width: 450px;
    width: 100%;

    &-title {
      margin-bottom: 16px;
      text-align: center;
      font-size: 24px;
      color: var(--color-primary);
    }

    &-close {
      font-family: 'MontserrattBold';
      cursor: pointer;
      padding: 4px;
      font-size: 14px;
    }
  }
}